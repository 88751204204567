import React, { Component } from 'react'

// Notifcation for form success message
// Documentation: https://teodosii.github.io/react-notifications-component/
import ReactNotification from 'react-notifications-component';
import "react-notifications-component/dist/theme.css";

// Form validation
import { Formik } from 'formik'

class Contact extends Component {
	constructor(props) {
		super(props);
		this.addNotification = this.addNotification.bind(this);
		this.notificationDOMRef = React.createRef();
	}

	addNotification() {
		this.notificationDOMRef.current.addNotification({
			title: "Thank You",
			message: "I will contact you within two days.",
			type: "success",
			insert: "top",
			container: "top-center",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: { duration: 2000 },
			dismissable: { click: true }
		});
	}

	render() {
		return (
			<Formik initialValues={{
				name: '',
				email: '',
				message: ''
			}} validate={values => {
				let errors = {};
				if (!values.email) {
					errors.email = 'Required';
				} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
					errors.email = 'Invalid email address';
				}
				return errors;
			}} onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
				fetch('https://pregd5z9ra.execute-api.us-east-1.amazonaws.com/prod/contact', {
					method: "POST",
					body: JSON.stringify({ name: values.name, email: values.email, message: values.message })
				}).then(() => {
					fetch('https://ozpg6gb4ak.execute-api.us-east-1.amazonaws.com/prod/jonagoot', {
						method: "POST",
						// headers: {
						//   'Content-Type': 'application/x-www-form-urlencoded',
						//   'x-api-key': 'ntRD0c0PR2TST2ehGsycJQsNS6S0VG1dHKF3Kxg0'
						// },
						body: JSON.stringify({ name: values.name, email: values.email, message: values.message })
					})
					resetForm();
				}).catch(error => alert(error));
			}} render={({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleReset,
				handleSubmit,
				isSubmitting
			}) => (<section>
				<ReactNotification isMobile="true" ref={this.notificationDOMRef} />
				<div className="inner">
					<section>
						<form onSubmit={handleSubmit}>
							<div className="field half first">
								<label htmlFor="name">Name</label>
								<input id="name" autoComplete="name" type="text" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} />
							</div>
							<div className="field half">
								<label htmlFor="email">Email</label>
								<input id="email" autoComplete="email" type="text" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} /> {touched.email && errors.email && <div>{errors.email}</div>}
							</div>
							<div className="field">
								<label htmlFor="message">Message</label>
								<textarea id="message" rows="6" type="text" name="message" onChange={handleChange} onBlur={handleBlur} value={values.message} />
							</div>
							<ul className="actions">
								<li><input onClick={this.addNotification} type="submit" disabled={isSubmitting} value="Send Message" className="special" /></li>
								<li><input type="reset" value="Clear" onClick={handleReset} /></li>
							</ul>
						</form>
					</section>
				</div>
			</section>)} />
		)
	}
}

export default (Contact);
