import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import Contact from '../components/Contact'

const contact = (props) => (
	<Layout>
		<div>
			<Helmet>
				<title>Jonathan Agoot - Contact Me</title>
				<meta name="description" content="Contact Jonathan Agoot" />
			</Helmet>
			<div id="main">
				<section id="banner" className="style2">
					<div className="inner">
						<header className="major">
							<h1>Contact Me</h1>
						</header>
						<div className="content">
							<p>Let's get the conversation started.</p>
						</div>
					</div>
				</section>
				<Contact />
			</div>
		</div>
	</Layout>
)

export default contact
